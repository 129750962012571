@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
/* Login page start */
.loginWrapper {
  display: flex;
}
.loginSideDiv {
  min-width: 210px;
  min-height: 100vh;
  background-color: #d9d9d9;
}
.loginMainDiv {
  background-color: #f5f5f5;
  width: 100%;
  /* height: 130vh; */
}
.modal-content {
  border-radius: 20px !important;
  border: 1px solid #ffffff00 !important;
}
.logoDiv {
  display: flex;
  justify-content: center;
  margin-top: 63px;
}
.logo {
  width: 78px;
  height: 78px;
}
.title {
  margin: auto 0;
  padding-left: 29.78px;
  font-weight: 700;
  font-size: 25.5273px;
  color: #353535;
}
.loginInputWrapper {
  background-color: #ffffff;
  border-radius: 16.9792px;
  max-width: 326px;
  min-height: 409px;
  margin: 0 auto;
  text-align: center;
}
.loginInputWrapperTitle {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  padding-top: 42px;
}
.mb-3.input-group {
  border-bottom: 1px solid #0512b0;
}
span#basic-addon1 {
  background-color: #fff;
  border: none;
}
input.form-control {
  background-color: #fff;
  border: none;
}
.inputWrapper {
  padding: 0 19px;
  margin-top: 46px;
}
.loginInputIcon {
  color: #0512b0;
  height: 19px;
  width: 18px;
}
.chechboxWrapper {
  display: flex;
  padding: 0 32px;
  margin-top: 27px;
}
.rememberP {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  font-family: "Poppins";
  margin-top: 4px;
  padding-left: 10px;
}
input.form-check-input {
  border: 1px solid #0512b0;
}
.loginButton {
  width: 145px;
  height: 32px;
  border-radius: 10px !important;
  border: none !important;
  background-color: #f9542f !important;
  margin: 0 auto;
}
.logoutButton {
  width: 100px;
  border-radius: 6px !important;
  border: none !important;
  background-color: #f9542f !important;
}
.rowTop {
  margin-top: 37px !important;
}
.recoverPasswordWrapper {
  margin-top: 31px;
  text-align: center;
}
.recoverPasswordWrapper p {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
}
.resetPassLink {
  text-decoration: underline;
  margin-top: -11px;
  padding-bottom: 22px;
}
.resetPassLink:hover {
  cursor: pointer;
}
.modal-dialog {
  max-width: 326px !important;
  min-height: 359px !important;
  border-radius: 16.9792px !important;
}
.modal-header {
  border-bottom: none !important;
}
.modelBoxText {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  font-family: "poppins";
}
.sendButton {
  width: 145px;
  border-radius: 10px !important;
  border: none !important;
  background-color: #0512b0 !important;
  margin: 0 auto;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-align: center !important;
}
/* Login page end */

/* sidenav start */
.sidenavWrapper {
  height: 100%;
  width: 250px;
  background: rgba(71, 73, 83, 0.25);
  position: fixed;
  overflow: auto;
}
.logoTitleWrapper {
  display: flex;
  padding-top: 25px;
  padding-left: 35px;
}
.sidenavLogo {
  height: 55px;
  width: 55px;
}
.mobile_logo {
  height: 40px;
  width: 40px;
  transform: translateX(-61px);
}
.iconText {
  padding-left: 35px;
}
.mobile_righticon {
  /* margin-left: auto; */
}
.mobile_logo1 {
 display: none;
}
.sidenavTitle {
  font-size: 18px;
  font-weight: 700;
  color: #353535;
  margin-top: 15px;
  padding-left: 21px;
}
.navListWrapper {
  margin-top: 82.88px;
}
.navUl {
  list-style-type: none;
}
.navLi {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
}
.navBtn {
  color: #000000 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 22px !important;
  cursor: pointer !important;
}
.listTop {
  margin-top: 36px;
}
.listIcon {
  height: 30px;
  width: 30px;
  color: #0000b5;
  padding-right: 12px;
}
.downIcon {
  color: #ffffff;
  float: right;
  padding-right: 34px;
  margin-top: 5px;
}
.downIcon1 {
  color: #ffffff;
  margin-left: 45.71px;
}
.legalDrop {
  margin-top: -51px;
  padding-left: 15px;
}
/* .legalDrop {
  display: none;
} */
/* .legalDropBtn:active+.legalDrop{
  display: block;
  position: absolute;
} */
/* .supportDrop {
  display: none;
} */
/* .supportDropBtn:active+.supportDrop{
  display: block;
  position: absolute;
} */
/* sidenav end */

/* dashboard start */
.numberOfSupport {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
ul.recharts-default-legend {
  display: none;
}
circle.recharts-dot.recharts-line-dot {
  display: none;
}
.dashboardChart1P {
  color: #0000b5;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.dashboardChart1Wrapper {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 10px 40px 10px 40px;
}
.chartRow {
  margin-bottom: 38px;
}
.progress-bar {
  background-color: rgba(0, 0, 181, 0.8) !important;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.col-lg-2.col-md-12.col-sm-12 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
/* .row {
  margin-right: 0 !important;
} */
.numberCard {
  min-height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}
.numberCardRow {
  min-height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}
.numberCardTitle {
  font-size: 16px;
  font-weight: 500;
  color: #0000b5;
  line-height: 24px;
  padding: 19px 12px;
  white-space: nowrap;
}
.progressDivWrapper {
  margin: -23px 11px;
}
.progressDiv {
  display: flex;
  height: 25px;
  justify-content: space-between;
}
.progressBar {
  margin-top: 4px;
  padding-left: 4px;
  width: 100%;
}
.pbOne {
  padding-left: 4px;
}
.pbTwo {
  padding-left: 4px;
}
.pbThree {
  padding-left: 4px;
}
.pbFour {
  padding-left: 4px;
}
.pdOne {
  margin-top: -20px;
}
.progressBar.progress {
  border-radius: 10px;
  height: 15px;
  width: 77%;
}
.progress-bar {
  background: rgba(0, 0, 181, 0.8);
}
.hashtagsList {
  display: flex;
  margin: -11px 15px;
  max-width: 275px;
}
.numberRight {
  margin-left: auto;
}
.userBase {
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 20px;
  padding: 19px 15px;
}
.userBase1 {
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 13px;
}
.userBase p {
  color: #0000b5;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.userBase span {
  color: #ff2600;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.userBase1 p {
  color: #0000b5;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.userBase1 span {
  color: #ff2600;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.numberP1 {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Poppins";
}
.numberP2 {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  font-family: "Poppins";
}
.hashtagP {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Poppins";
  overflow : hidden;
  text-overflow : ellipsis;
  position: relative;
 
   
}
/* dashboard end */

/* list of user start */
.tableTRSearch {
  border-bottom: 2px solid #e6e6f8 !important;
}
.userListTop {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  font-family: "Poppins";
}
input.me-2.form-control {
  height: 35px !important;
  background-color: rgba(217, 217, 217, 1) !important;
}
.linkUser {
  text-decoration: none !important;
}
.linkUserText {
  text-decoration: none !important;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}
.tableBody {
  background-color: #e6e6f8;
  border-radius: 20px;
}
.tableImg {
  height: 44px;
  width: 44px;
  border-radius: 50%;
}
.simpleBtn {
  width: 86px;
  height: 39px;
  background-color: #ffffff !important;
  border-radius: 10px !important;
  border: none !important;
  color: #61C513 !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-transform: capitalize;
  margin-right: 10px;
}
.refuseBtn {
  width: 86px;
  height: 39px;
  background-color: #ffffff !important;
  border-radius: 10px !important;
  border: none !important;
  color: #FF0000 !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-transform: capitalize;
}

a.page-link:hover {
  background: #fff !important;
}
.tableHeadTxt {
  color: #7d7777;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 27px;
}
.tableTxt {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: 27px;
  padding-top: 18px !important;
}
.tableTR {
  border-bottom: 2px solid rgba(255, 255, 255, 1);
}
a.page-link {
  background: #f5deb300;
  color: #000 !important;
  border: none !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #000 !important;
  /* background-color: #ffffff !important;
  border-color: #ffffff !important; */
}
.rtlBtn {
  color: #fff;
  padding: 8px 4px;
}
/* list of user end */

/* configration start */
.giftListDiv {
  overflow: auto;
}
.minimumAmount {
  width: 100%;
  min-height: 207px;
  background-color: #bfbfec;
  border-radius: 20px;
  padding: 15px 17px;
}
.maximum {
  min-height: 111px;
  width: 100%;
  background-color: #bfbfec;
  border-radius: 20px;
  padding: 15px 17px;
}
.paymentMethod {
  min-height: 207px;
  width: 100%;
  background-color: #bfbfec;
  border-radius: 20px;
  padding: 15px 17px;
}
.minimumText {
  color: #0000b5;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 27px;
}
.paymentMethodText {
  color: #0000b5;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 27px;
}
.minimumData {
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
}
.maximumData {
  display: flex;
  justify-content: space-between;
  margin-top: 24.96px;
  width: 71%;
}
.minimumDataP1 {
  color: #000000;
  font-size: 26px;
  font-weight: 600;
  font-family: Inter;
  line-height: 31px;
}
.minimumDataP2 {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: 27px;
  text-decoration: underline !important;
  cursor: pointer;
}
.paymentMethodP1 {
  padding-left: 33.25px;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  font-family: Inter;
  line-height: 22px;
}
.paymentMethodP2 {
  padding-left: 33.25px;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  font-family: Inter;
  line-height: 22px;
}
.wofichCommision {
  width: 100%;
  min-height: 335px;
  background-color: #bfbfec;
  border-radius: 20px;
  padding: 11px 18px;
}
.wofichCommisionText {
  color: #0000b5;
  font-size: 18px;
  font-weight: 600;
  font-style: "Poppins";
  line-height: 27px;
  /* margin-bottom: 5px; */
}
.sidebarIcon {
  padding-right: 12px;
}
.wofichText {
  display: flex;
  justify-content: space-between;
  margin-top: -19x;
}
.wofichTextP1 {
  color: #f9542f;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
}
.wofichTextP2 {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-decoration: underline !important;
  cursor: pointer;
}
.wofichSpan {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-top: -3px;
}
.ActivitysDiv {
  width: 100%;
  height: auto;
  background-color: #bfbfec;
  border-radius: 20px;
  padding: 19px 18px;
  margin-bottom: 20px;
}
.midText {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin-top: 18px;
  margin-bottom: 17px;
  font-family: 'Poppins';
}
.midItalic {
  font-family: 'Poppins';
}
.divWrapperConfig {
  overflow: auto;
  min-width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 19px 13px 13px 24px;
}
.activityList {
  min-height: 310px;
}
.objectiveList {
  min-height: 310px;
}
.HashtagList {
  min-height: 310px;
  overflow-y: scroll;
}
.activityListTitle {
  color: #0000b5;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.plusIcon:hover {
  cursor: pointer;
}
.plusIcon {
  color: #0000b5;
  height: 20px;
  width: 20px;
}
.activitytarget {
  color: #f9542f;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.objactiveTarget {
  color: #0000b5;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.hashtagTarget {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
}
.activityBtns {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-decoration: underline !important;
  cursor: pointer;
}
.textUnderLine {
  text-decoration: underline !important;
  cursor: pointer;
}
.btnModify {
  margin-left: 8px;
}
.logoIcon {
  margin-left: -12px;
  margin-right: 7px;
  height: 40px;
  width: 40px;
}
.modal-dialog {
  margin: 10% auto !important;
}
.giftListDiv {
  max-width: 100%;
  height: 350px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 22px 22px;
}
.giftListDivWrapper {
  /* width: 780px; */
  height: 350px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 22px 22px;
  overflow: auto;
}
.giftListDivWrapperText {
  overflow: auto;
}
.giftListTR {
  border-bottom: 2px solid rgba(125, 119, 119, 1);
}
.giftListTR th {
  color: #7d7777;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  font-size: "Poppins";
}
.gtr {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  font-size: "Poppins";
}
.giftListTR1 {
  border-bottom: 2px solid rgb(255, 255, 255);
}
.activeDivModalBox .modal-content {
  border: 1px solid #d9d9d9 !important;
  background-color: #d9d9d9;
  border-radius: 10px;
}
.modalBoxTitle {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
}
input.me-2.modalBoxSearchBar.form-control {
  background-color: #ffffff !important;
}
.modalConfirm {
  width: 100px;
  height: 39px;
  background-color: #f9542f !important;
  border: 1px solid #f9542f !important;
  border-radius: 10px !important;
  margin-top: 25px;
  text-transform: uppercase;
  font-weight: 600 !important;
  padding: 8px 10px !important;
}
.addCameraIcon {
  font-size: 50px;
  color: #000000;
  margin-bottom: 33px;
}
/* configration end */

/* cgu start */
.cguDisplay {
  display: flex;
  justify-content: space-between;
}
.cguTitle {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.cguEditBtn {
  width: 106px;
  height: 53px;
  background-color: #0000b5 !important;
  border: #0000b5 !important;
  border-radius: 15px !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px !important;
}
.cguEditBtn1 {
  width: 106px;
  height: 53px;
  background-color: #f9542f !important;
  border: #f9542f !important;
  border-radius: 15px !important;
}
.cguText {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.addDocument {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  font-family: "Poppins";
  text-decoration: underline !important;
  cursor: pointer;
}
.cguTitle1 {
  color: #7d7777;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}
.cgvEditingText {
  padding: 12px 27px;
  background-color: #eaeaea;
  border-radius: 20px;
}
/* cgu end */

/* support start */
.supportTableBackground {
  background-color: #e6e6f8 !important;
  min-width: 768px !important;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
/* .supportPadding {
  padding: 46px 63px !important;
} */
.divWrapper {
  overflow: auto;
  width: 100%;
}
.divWrapper1 {
  overflow: auto;
  width: 100%;
  padding-top: 10px;
}

.divWrapperCoach {
  padding: 0 10px;
}
.supportTitle {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  font-family: "Poppins";
}
.tableHeadTxt1 {
  color: #7d7777;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: 24px;
}
.tableTxt1 {
  color: #353535;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: 24px;
}
.progressCls {
  color: #61c513;
  font-weight: 700;
}
.closed {
  color: #353535;
  font-weight: 700;
}
.close{
  cursor: pointer;
  font-size: 28px;
  position: absolute;
  top: 0px;
}
.panding {
  color: #edd500;
  font-weight: 700;
}
.urgrntCls {
  color: #353535;
  font-weight: 700;
}
.standerdCls {
  color: #353535;
  font-weight: 500;
}
.tableBody12 {
  background-color: #e6e6f8;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  min-width: 768px;
}
.actBox p {
  color: #353535;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.actBox1 p {
  color: #0000b5;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.actBox111 p {
  color: #0000b5;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.actBox {
  background-color: #e6e6f8;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 250px;
  height: 55px;
  padding: 15px 29px;
}
.actBox1 {
  background-color: rgba(191, 7, 15, 0.2);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 250px;
  height: 55px;
  padding: 15px 29px;
}
.actBox111 {
  background-color: rgba(191, 7, 15, 0.2);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 250px;
  height: 55px;
  padding: 15px 29px;
}
.resBox p {
  color: #0000b5;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.resBox1 p {
  color: #353535;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.resBox111 p {
  color: #353535;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.resBox {
  background-color: rgba(191, 7, 15, 0.2);
  margin-left: 18px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 250px;
  height: 55px;
  padding: 15px 14px;
}
.resBox1 {
  background-color: #e6e6f8;
  margin-left: 18px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 250px;
  height: 55px;
  padding: 15px 14px;
}
.resBox111 {
  background-color: #e6e6f8;
  margin-left: 18px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 250px;
  height: 55px;
  padding: 15px 14px;
}
.Resolved {
  color: #0000b5;
  font-size: 16px;
  font-weight: 700;
  font-family: "Poppins";
  line-height: 24px;
}
.actUnder {
  text-decoration: none !important;
}
.tableTxt111 {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  font-family: "Poppins";
}
.tableTxt112 {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  font-family: "Poppins";
  text-decoration: underline;
}

/* support end */

/* report start */
.txtWidth {
  width: 159px;
}
.txtWidth1 {
  width: 324px;
}
.txtWidth2 {
  width: 309px;
}
.rrsov {
  border-bottom: 2px solid #e6e6f8 !important;
}
/* report end */

/* coach start */
.tableBodyCoachTable {
  min-width: 1044px;
  margin: 10px 10px;
}
.coachUserDate {
  display: flex;
  justify-content: space-between;
}
.accepté {
  width: 95px !important;
}
.refuserBtn {
  color: #ff2600 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  text-transform: capitalize;
  text-decoration: underline !important;
}
/* .sidebarMenu {
  background: rgba(71, 73, 83, 0.25);
  position: fixed; 
  height: 100%;
  width: auto !important; 
  overflow: auto;
} */
.loremUnderLine {
  text-decoration: underline;
}
.usersIcon {
  color: #f9542f;
  font-size: 34px;
  margin-top: 46px;
}
.usersText {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  list-style: 22px;
}
.usersText1 {
  color: #ffffff;
  margin-top: -23px;
}
.popover-body {
  background: #c7c7e9;
  width: 666px !important;
  padding: 39px 30px !important;
  border-radius: 5px !important;
}
.coachUserRaring {
  margin: 0 40px;
  text-align: center;
}
div#popover-basic {
  border: none !important;
}
.tableImg1 {
  height: 144px;
  width: 144px;
  border-radius: 50%;
}
.userDataName {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 34px;
}
.userDataName1 {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  margin:0px 0px 0px;
}
.userInfo {
  color: #474953;
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  margin: 0px;
}
/* coach end */

/* user detail start */
.Srchbox {
  width: 251px;
  padding-top: 16px;
}
.detailsTopDiv {
  background: #f8f9fd;
  border-radius: 15px;
  padding: 21px 20px;
  min-width: 798px;
  margin-top: 29px;
}
.detailsTopDivHead {
  background: #f8f9fd;
  border-radius: 15px;
  padding: 21px 20px;
  /* min-width: 798px; */
  margin-top: 29px;
}
.detailsTopDiv1 {
  display: flex;
  justify-content: space-between;
}
.detailsTopDivP {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
}
.detailsTopDivP1 {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.detailsTopDivP2 {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.modButton {
  color: #0000b5 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.deleButton {
  color: #ed0000 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.detailHead {
  display: flex;
  flex-wrap: wrap;
}
.detailTop {
  display: flex;
  justify-content: space-between;
}
.modirefuserBtn {
  color: #0000b5 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.deactButton {
  color: #000000 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 27px !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.dTDiv3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dTDiv3 span {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}
.dTDiv3 p {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  display: flex;
}
button#dropdown-basic\ simpleDrop {
  color: #000 !important;
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: "Poppins" !important;
  line-height: 30px !important;
  margin-top: -8px;
}
button#dropdown-basic\ simpleDrop:focus {
  box-shadow: none !important;
}
.blueColorProgram {
  color: #0000b5 !important;
}
select.dropBtnss.form-select{
  border: 1px solid #ff000000 !important;
  background-color: rgba(255, 0, 0, 0) !important;
  /* padding: 2px !important; */
  margin-top: -16px !important;
  color: #000!important;
  font-family: Poppins!important;
  font-size: 20px!important;
  font-weight: 400!important;
  line-height: 30px!important;
}
select.dropBtns.form-select{
  border: 1px solid #ff000000 !important;
  background-color: rgba(255, 0, 0, 0) !important;
  /* padding: 2px !important; */
  margin-top: -9px !important;
  color: #000!important;
  font-family: Poppins!important;
  font-size: 20px!important;
  font-weight: 400!important;
  line-height: 30px!important;
}
.selectUser {
  min-width: 150px !important;
  color: #000000 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  display: flex !important;
} 
.dropTxt {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Poppins";
}
.coachWrapper {
  min-width: 798px;
  border: 20px;
  padding: 36px 26px;
  background-color: #feeeea;
  border-radius: 15px;
  margin-top: 40px;
}
.studentsWrapper {
  min-width: 798px;
  border: 20px;
  padding: 36px 26px;
  background-color: #f8f9fd;
  border-radius: 15px;
  margin-top: 40px;
}
.studenrListBorder {
  border-bottom: #f8f9fd;
}
.coachWrapper p {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
}
.studentsWrapper p {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
}
.coachListBorder {
  border-bottom: 2px solid #feeeea;
}
.tableBody1 {
  background-color: #feeeea;
}
.txttable1 {
  color: #0000b5 !important;
}
.badgeList {
  /* background-color: #F9542F; */
  border: 1px solid #f9542f !important;
  border-radius: 10px;
  padding: 0 14px;
  margin: 3px 5px;
  cursor: pointer;
}
.selected{
  background-color: #f9542f;
  border: 1px solid #f9542f !important;
  border-radius: 10px;
  padding: 0 14px;
  color: #ffffff !important;
}
.showMoreButton {
  position: absolute;
  left: 0; /* Position the button on the left side */
  /* Add other styling as needed */
}
.activeLink {
  background-color: red; /* Change this to your desired active link background color */
  color: #fff; /* Change this to your desired active link text color */
  /* Add any other styles you want for active links */
}

.activeBadgeList {
  background-color: #f9542f;
  border: 1px solid #f9542f;
  border-radius: 10px;
  padding: 0 14px;
  margin: 3px 5px;
  color: #ffffff;
}

.loader-container {
  background: #9e9e9e14;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Set container height to viewport height for vertical centering */
}
.activeBadgeList .badgeListP {
  color: #ffffff;
}
.badgeList:active {
  background-color: #f9542f;
  border: 1px soolid #f9542f;
  border-radius: 10px;
  padding: 0 14px;
  margin: 3px 5px;
  color: #ffffff;
}
.badgeList:active .badgeListP {
  color: #ffffff;
}
.badgeListP {
  color: #000000;
  font-size: 18px !important;
  font-weight: 600;
  line-height: 27px;
  font-family: "Poppins" !important;
  margin-bottom: 2px;
}

.clickForDetail {
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  font-family: "Poppins" !important;
  font-style: italic !important;
  margin-top: 37px;
  margin-bottom: 10px;
}
.listOfObjective {
  min-width: 798px;
  margin-top: 48px;
  border-radius: 15px;
  background-color: #e4efff;
  padding: 17px 17px;
}
.listOfObjectiveMain {
  min-width: 798px;
  margin-top: 12px;
  border-radius: 15px;
  background-color: #e4efff;
  padding: 17px 17px;
}
.listOfObjectiveTitle {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}
.listOfObjective1 {
  font-family: "Poppins";
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.transferMainDiv1Title {
  font-family: "Poppins";
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.listOfObjective2 {
  font-family: "Poppins";
  font-style: italic;
  color: #000000;
  text-decoration: underline !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
}
.listOfObjectiveDiv {
  display: flex;
  justify-content: space-between;
}
.incomeMainDiv {
  background-color: #f8f9fd;
  border-radius: 15px;
  margin-top: 45px;
}
.incomeMainDivTitle {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  padding: 19px 19px;
}
.transferShow {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  font-family: "Poppins";
  font-style: italic;
  text-decoration: underline !important;
  cursor: pointer;
}
.paymentMethodMainTitle {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  font-family: "Poppins";
}
.paymentMethodMainDivP1 {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  font-family: "Poppins";
}
.paymentMethodMainDivP2 {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  font-family: "Poppins";
  margin-top: -14px;
}
button.makeTransferMainBtn.btn.btn-primary:active {
  background: #f9542f !important;
}
.paymentMethodMainDiv {
  border-bottom: 1px solid #7d7777;
}
.incomeMainDiv1 {
  border-radius: 15px;
  background-color: #edc9cd;
  padding: 27px 16px;
}
.incomeMainDiv2 {
  border-radius: 15px;
  background-color: #edc9cd;
  padding: 16px 25px;
  max-width: 386px;
  margin-top: 23px;
  margin-bottom: 10px;
}
.transferMainDiv {
  border-radius: 15px;
  background-color: #f8f9fd;
  padding: 17px 20px;
  margin-top: 48px;
}
.transferMainDiv1 {
  /* min-width: 662px; */
  border-radius: 15px;
  background-color: #e4efff;
  padding: 13px 15px;
  margin-top: 22px;
}
.paymentMethodMain {
  border-radius: 15px;
  background-color: rgba(249, 84, 47, 0.1);
  padding: 26px 35px;
  margin-top: 48px;
}
.borderBot {
  border-bottom: 2px solid #e4efff;
}
.transList {
  font-weight: 500 !important;
  font-size: 17px !important;
}
.transList1 {
  font-weight: 400 !important;
  color: #230b34 !important;
}
.incomeP2 {
  border-bottom: 2px solid #260103;
  width: 84%;
}
.incomeMainP {
  color: #000000;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 27px;
}
.incomeMainDate {
  color: #000000;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 27px;
}
.incomeMainDataTitle {
  color: #000000;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 27px;
}
.transferMainTitle {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  line-height: 27px;
}
.makeTransferMainTitle {
  color: #000000;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 27px;
}
.walletMainDivTitle {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
}
.walletMainDivTitle1 {
  color: #0000bf;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.walletMainDiv2Title {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  font-family: "Poppins";
}
.walletMainDivDate {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  font-family: "Poppins";
}
.walletMainDiv2Show {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  font-family: "Poppins";
  font-style: italic;
  text-decoration: underline !important;
  margin-right: 8px;
  cursor: pointer;
}
.makeTransferMainText {
  margin-top: 25px;
  color: #000000;
  font-size: 14px;
  font-style: italic;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 21px;
}
.makeTransferMainInput span {
  color: #000000 !important;
}
.makeTransferMainInput {
  color: rgba(71, 73, 83, 0.4);
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 49px;
  background-color: #ffffff;
  width: 124px;
  height: 53px;
  padding: 0 20px;
  border-radius: 19px;
}
.dateP1 {
  margin-top: -10px;
}
.incomeMainP span {
  color: #0000b5;
}
.incomeMainData {
  display: flex;
  justify-content: space-between;
}
.walletMainDivDateDiv {
  display: flex;
  justify-content: space-between;
}
.makeTransferMainBtn {
  background-color: rgba(71, 73, 83, 0.8) !important;
  border: none !important;
  border-radius: 10px !important;
  max-width: 163px !important;
}
.walletMainDiv {
  background-color: rgba(249, 84, 47, 0.1);
  border-radius: 15px;
  padding: 17px 20px;
  margin-top: 48px;
}
.walletMainDiv2 {
  background-color: #e4efff;
  border-radius: 15px;
  padding: 11px 15px;
  margin-top: 5px;
}
.searchText {
  margin-top: 10px;
}
.fa-search:before {
  content: "\f002";
  margin-left: -50px;
}
.adminDashboard {
  display: flex;
}
.programDrop {
  color: #000000;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: "Poppins" !important;
  line-height: 24px !important;
  margin-bottom: -1px;
}
.badgeListUser {
  display: inline-flex;
  margin-top: 40px;
  white-space: nowrap;
  flex-wrap: wrap;
}
/* user detail end */
.mobile_header {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

/* header start */
main {
  width: 100%;
  padding: 20px;
}
.container_text{
  background-color: rgba(71, 73, 83, 0.25);
}
.sidebar {
  background-color: rgba(71, 73, 83, 0.25);
  color: #fff;
  height: 100vh;
  width: 250px;
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
}
.rightSection {
  /* background: #F8F9FD; */
  width: 100%;
  padding-left: 270px; 
  padding-right: 20px;
}
.right_section {
  padding-left: 15px;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.logo {
  font-size: 25px;
}
.link {
  display: flex;
  color: #fff;
  padding: 10px 26px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover {
  background-color: lightskyblue;
  color: #000;
  transition: all 0.5s;
}
.active {
  background-color: lightskyblue;
  color: #000;
}
.icon, .link_text {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}
.subMenu {
  margin-left: 16px;
}
.subNav {
  background: red;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
}
.subNav :hover {
  background: #632ce4;
  cursor: pointer;
}
/* header end */

/* pagenation css start */
.pagenationBtn{
  
  width: 95%;
  overflow:auto;
  padding:5px 10px 9px 15px;
  margin-bottom: 0px;

}
.pagenationBtn::-webkit-scrollbar{
  display: none;

}
.pt-18{
  padding-top:18px !important;
}
/* responsive start*/

@media screen and (max-width: 1354px) {
  /* dashboard */
  .numberCardTitle {
    white-space: normal;
  }
  /* dashboard */
}
@media screen and (max-width: 1161px) {

}
@media screen and (max-width: 1404px) {
}
@media screen and (max-width: 1024px) {
  /* sidebar */
  /* sidebar */
}
@media screen and (max-width: 640px) {
  /* sidebar */
  /* sidebar */
}
@media screen and (max-width: 991px) {
  /* dashboard */
  .adminDashboard {
    display: block;
    padding: 5px;
    background: rgb(248, 249, 253);
  }
  .rightSection {
   
    padding-left: 15px; 
    /* padding-right: 20px; */
  }
  .mobile_header {
    display: flex;
  }
  .sidebar {
    display: none;
  }
  .rowTop {
    margin-top: 0px !important;
  }
  .numberCardRow {
    margin-top: 30px;
  }
  .mobile_header .sidebar {
    position: fixed;
    display: block;
    left: -100%;
    top: 0;
    height: 100%;
    transition: ease all 0.5s;
    background: #d1d1d4;
}
.mobile_header .sidebar.sidebarActive {
  left: 0;
}
  .userBase {
    /* padding-left: 0px; */
    margin-top: 50px;
  }
  /* dashboard */
  /* Login start */
  .loginSideDiv {
    display: none;
  }
  .loginMainDiv {
    width: 100%;
  }
  /* Login end */
  /* sidebar start */
  .sidenavWrapper {
    min-height: auto !important;
  }
  /* sidebar end*/
  /* List of users */
  .tableScroll {
    width: 990px !important;
  }
  .tableScrollDiv {
    width: 100% !important;
    overflow: auto;
  }
  /* List of users */
  /* configration */
  .activityList {
    min-width: 500px;
  }
  .objectiveList {
    min-width: 500px;
  }
  .paymentMethod {
    margin-top: 15px;
  }
  .wofichCommision {
    margin-top: 15px;
  }
  .activityListTitle {
    margin-top: 15px;
  }
  .divWrapperConfig {
    overflow: auto;
    width: 100%;
  }
  /* configration */

  /* coach */

  .divWrapperCoach {
    overflow: auto;
    width: 100%;
  }
  /* coach */
}
@media screen and (max-width: 768px) {

  .detailTop {
    display: grid;
  }

  .dTDiv3 {
    display: grid;
  }

  .incomeMainDiv2 {
    padding: 16px 7px;
  }
  .badgeList {
    white-space: normal;
  }
  .supportPadding {
    padding: 20px 20px !important;
  }
  .coachUserDate {
    display: grid;
  }
  .coachUserRaring {
    margin: 0 10px;
  }
  .tableImg1 {
    height: 72px;
    width: 72px;
  }
  .popover-body {
    padding: 5px 5px !important;
    width: 248px !important;
  }
}
@media screen and (max-width: 520px) {
  .cguDisplay {
    display: grid;
    padding: 5px 0;
  }
}

/* responsive end*/
/* slider */

.carousel-control-prev-icon {
background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2UlEQVR4nO2ZSwrCQBBEKx5m+miCXkLjd+sHXZhTdu8dQRSCmIirmYJ6MPt6lYZMOoAQQvxPbpD8hBRz8JEbmJ9hkWF+J5N4Nf8M/z7OItFv/uOkuIKg+ePX8BY3IE9QefgDaXgAFnuFL4LFjrf5FFuFL0KKDXPza4UvQooVb/MWrcIXwWLJ3PyCN/y4QMchMD5CVBKtJCp/kRGNUxq8B0milus01ZPYSqLyj3qicbLBnZAkalktdiTjNLrc7XgkeNfrv35w+Ay8v5icKXxfIi4wn5ZOIoRAWR4h/BDJX8nYIgAAAABJRU5ErkJggg==') !important;
width: 20px !important;
height: 18px !important;
position: absolute;
left: 5px;
top: 61%;

}
.carousel-control-next-icon {
background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3ElEQVR4nO2ZSw7CMAwFXzlMfDCQuAZbKFAWfATc0l4jIxBd8FHWfiYjZT/TqGmaAI1G488pOoPYBfAJOOX1BjGH2JUrorzJj+MxEx0oKHb6kH8N3ZNEePeU/RVR7NwiYsyE7vhnQnQAB54+YgsOPH3EBhx4bXVagwNPH9GDg+q2YwUOskeILZEgYgGOADuQBnhNfgla+RL+JU4rrz2zfPStRFZ5Cb+d9pp89B8aTys/IDaeVL6EP9jy2hc2+vmoM8sjwfF60Sn3Bcd3BNkV04joHGJHriffaDQQhTtWlRKc/Zi3rQAAAABJRU5ErkJggg==') !important;
width: 20px !important;
height: 18px !important;
position: absolute;
right: 5px;
top: 61%;
}
.carousel-inner .active{
  background-color: #758c9a00 !important;
  color: #000;
}
.hide-arrow {
  background-image: none !important;

}

/* Comfirmation */
.react-confirm-alert-body {
  text-align: center !important;
}
.react-confirm-alert-body > h1 {
  margin: 0px 0px 27px;
  font-size: 19px;
  padding: 0px 1px 13px;
  text-align: center;
  color: #1a1919;
  border-bottom: 1px dashed #ccc;
}
.react-confirm-alert-button-group {
  justify-content: center !important;
  margin-top: 20px;
  margin-top: 26px;
  border-top: 1px dashed #ccc;
  padding: 11px 0px 0px;
}
.react-confirm-alert-button-group button:nth-child(1) {
  background: #274bcc;
}

/* Selects the second li element in a list */
.react-confirm-alert-button-group button:nth-child(2) {
  background: #aa1717;
}